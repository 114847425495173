import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem } from '@mui/material';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { fDateTime } from 'src/utils/formatTime';

// ----------------------------------------------------------------------

AdministratorTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function AdministratorTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const theme = useTheme();

  const { FirstName, LastName, FbId, SchoolName, Email, PhoneNumber1, IsDeleted, LastLogin } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell align="left">{FirstName}</TableCell>
      <TableCell align="left">{LastName}</TableCell>
      <TableCell align="left">{SchoolName}</TableCell>
      <TableCell align="left">{Email}</TableCell>
      <TableCell align="left">{PhoneNumber1}</TableCell>
      <TableCell>{fDateTime(LastLogin)}</TableCell>
      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={IsDeleted == 1 ? 'error' : 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {IsDeleted == 0 ? "Aktivna" : "Inaktivne"}
        </Label>
      </TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Izmeni
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
