import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from 'src/theme/overrides/Chip';

// ----------------------------------------------------------------------

RHFMultiSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function RHFMultiSelect({ name, children, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-multiple-checkbox-label">{other.placeholder}</InputLabel>
              <Select
                {...field}
                labelId="demo-multiple-checkbox-label"
                multiple
                fullWidth
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                input={<OutlinedInput label="Tag" />}
                placeholder="asd"
                id="demo-multiple-checkbox"
                value={field.value || []}
                error={!!error}
                helperText={error?.message}
                // renderValue={(selected) => {
                //   return (
                //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                //       {selected.map((value) => (
                //         <Chip key={value} label={value} />
                //       ))}
                //     </Box>
                //   )
                // }}
                {...other}
              >
                {other && other.options.map((name) => (
                  <MenuItem key={name.value} value={name.value}>
                    <ListItemText primary={name.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>)
      }}
    />
  );
}
