import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, FormControlLabel, MenuItem, ListItemText } from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock
import { countries } from '../../../_mock';
// components 
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import RHFMultiSelect from 'src/components/hook-form/RHFMultiSelect';
import cities from "../../../cities.json";
import axiosInstance from 'src/utils/axios';
// ----------------------------------------------------------------------

SchoolNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentEditData: PropTypes.object,
};

const categories = [
  { value: "AM", label: 'AM' },
  { value: "A1", label: 'A1' },
  { value: "A2", label: 'A2' },
  { value: "A", label: 'A' },
  { value: "B1", label: 'B1' },
  { value: "B", label: 'B' },
  { value: "BE", label: 'BE' },
  { value: "C1", label: 'C1' },
  { value: "C1E", label: 'C1E' },
  { value: "C", label: 'C' },
  { value: "CE", label: 'CE' },
  { value: "D1", label: 'D1' },
  { value: "D1E", label: 'D1E' },
  { value: "D", label: 'D' },
  { value: "DE", label: 'DE' },
  { value: "F", label: 'F' },
  { value: "M", label: 'M' },
];

export default function SchoolNewEditForm({ isEdit, currentEditData }) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    Name: Yup.string().required('Ime je obavezno'),
    FrendlyName: Yup.string().required('Kratko ime je obavezno'),
    PIB: Yup.string().required('PIB je obavezan'),
    IdentificationNumber: Yup.string().required('Maticni broj je obavezan'),
    AccountNumber: Yup.string().required('Broj racuna je obavezan'),
    Email: Yup.string().required('Email je obavezan').email("Format email nije dobar"),
    City: Yup.string().required('Grad je obavezan'),
    Zip: Yup.string().required('ZIP je obavezan'),
    Address1: Yup.string().required('Adresa je obavezna'),
    PhoneNumber1: Yup.string().required('Broj telefona je obavezan'),
    UniqueNumberStarts: Yup.string().required('Jedinstveni broj je obavezan'),
    Categories: Yup.array().required('Kategorija je obavezna')
  });

  const defaultValues = useMemo(
    () => ({
      Name: currentEditData?.Name || '',
      FrendlyName: currentEditData?.FrendlyName || '',
      PIB: currentEditData?.PIB || '',
      IdentificationNumber: currentEditData?.IdentificationNumber || '',
      AccountNumber: currentEditData?.AccountNumber || '',
      Email: currentEditData?.Email || '',
      Country: currentEditData?.Country || '',
      City: currentEditData?.City || true,
      Zip: currentEditData?.Zip || '',
      Address1: currentEditData?.Address1 || '',
      Address2: currentEditData?.Address2 || '',
      PhoneNumber1: currentEditData?.PhoneNumber1 || '',
      PhoneNumber2: currentEditData?.PhoneNumber2 || '',
      UniqueNumberStarts: currentEditData?.UniqueNumberStarts || '',
      Categories: currentEditData?.Categories.map(m => m.category) || [],
      IsVAT: currentEditData?.IsVAT || false,
      IsDeleted: currentEditData?.IsDeleted || false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentEditData]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentEditData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentEditData]);

  const onSubmit = async (data) => {
    try {
      if (!isEdit) {
        await axiosInstance.post("/office/schools", data);
      } else {
        await axiosInstance.put("/office/schools", { ...data, Id: currentEditData.Id });
      }

      reset();
      enqueueSnackbar(!isEdit ? 'Uspesno kreirana nova skola!' : 'Update uspesno prosao!');
      navigate(PATH_DASHBOARD.school.list);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="Name" label="Naziv" />
              <RHFTextField name="FrendlyName" label="Kratko ime" />
              <RHFTextField name="PIB" label="PIB" />
              <RHFTextField name="IdentificationNumber" label="Maticni broj" />
              <RHFTextField name="AccountNumber" label="Broj racuna" />
              <RHFTextField name="Email" label="Email" />
              <RHFTextField name="Country" label="Zemlja" disabled={true} value={'Srbija'} />
              <RHFSelect name="City" label="Grad" placeholder="Grad">
                <option value="" />
                {cities.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </RHFSelect>
              <RHFTextField name="Zip" label="Postanski broj" />
              <RHFTextField name="Address1" label="Ulica i broj" />
              <RHFTextField name="Address2" label="Ulica i broj 2" />
              <RHFTextField name="PhoneNumber1" label="Broj telefona" />
              <RHFTextField name="PhoneNumber2" label="Broj telefona 2" />
              <RHFTextField name="UniqueNumberStarts" label="Broj Auto skole" />

              <RHFMultiSelect name="Categories" label="Kategorije" placeholder="Kategorije" options={categories}> </RHFMultiSelect>

              <RHFSwitch
                name="IsVAT"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      U PDV-u
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />

              <RHFSwitch
                name="IsDeleted"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Inaktivna
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Dodaj novu skolu' : 'Sacuvaj izmene'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
