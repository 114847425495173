import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { GoogleLoginButton } from "react-social-login-buttons";
import { GoogleAuthProvider } from "firebase/auth";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  async function loginWithGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ 'login_hint': 'email@gmail.com' });

      const result = await login(provider);


      setError("Ovaj Google nalog nije povezan ni sa jednim nalogom u aplikaciji.");
      // await firebase.auth().currentUser.delete();



      return true;
    } catch (error) {
      // console.log(error)
      setError("Nešto nije u redu. Molimo Vas pokušajte kasnije!");
    }
  }


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {/* {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="Email adresa" />

        <RHFTextField
          name="password"
          label="Loznika"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}

        <GoogleLoginButton onClick={() => loginWithGoogle()} text="Google nalog" className="py-5 px-6 mt-2" style={{ fontSize: 12 }} />

      </Stack>
      {/* 
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}> 
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Zaboravili ste lozinku?
        </Link>
      </Stack> */}

      {/* <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Prijava
      </LoadingButton> */}
    </FormProvider>
  );
}
