import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, FormControlLabel, MenuItem, ListItemText } from '@mui/material';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// components 
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField } from '../../../components/hook-form';
import axiosInstance from 'src/utils/axios';
// ----------------------------------------------------------------------

AdministratorNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentEditData: PropTypes.object,
  schools: PropTypes.array,
};

const roles = [
  { value: 1, label: 'Administrator' },
];

export default function AdministratorNewEditForm({ isEdit, currentEditData, schools }) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    FirstName: Yup.string().required('Ime je obavezno'),
    LastName: Yup.string().required('Kratko ime je obavezno'),
    FbId: Yup.string().required('PIB je obavezan'),
    CarSchoolFk: Yup.number().required('Škola je obavezna'),
    Email: Yup.string().email('Email nije validan').required('Email je obavezan'),
  });

  const defaultValues = useMemo(
    () => ({
      FirstName: currentEditData?.FirstName || '',
      LastName: currentEditData?.LastName || '',
      FbId: currentEditData?.FbId || '',
      RoleFk: currentEditData?.RoleFk || 1,
      CarSchoolFk: currentEditData?.CarSchoolFk || 3,
      Email: currentEditData?.Email || '',
      PhoneNumber1: currentEditData?.PhoneNumber1 || '',
      PhoneNumber2: currentEditData?.PhoneNumber2 || '',
      IsSuperUser: currentEditData?.IsSuperUser || false,
      IsHost: currentEditData?.isHost || false,
      NotifyDailyReports: currentEditData?.NotifyDailyReports || false,
      NotifyMontlyReports: currentEditData?.NotifyMontlyReports || false,
      NotifyYearlyReports: currentEditData?.NotifyYearlyReports || false,
      NotifyLicenceHR: currentEditData?.NotifyLicenceHR || false,
      NotifyExpiredContractSpace: currentEditData?.NotifyExpiredContractSpace || false,
      ExpiredMontlyCheckVehicles: currentEditData?.ExpiredMontlyCheckVehicles || false,
      ExpiredRegistration: currentEditData?.ExpiredRegistration || false,
      NotifyRoute: currentEditData?.NotifyRoute || false,
      NotifyExams: currentEditData?.NotifyExams || false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentEditData]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentEditData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentEditData]);

  const onSubmit = async (data) => {
    try {
      if (!isEdit) {
        await axiosInstance.post("/office/administrators", data);
      } else {
        await axiosInstance.put("/office/administrators", { ...data, Id: currentEditData.Id });
      }

      reset();
      enqueueSnackbar(!isEdit ? 'Uspesno kreiran novi administrator!' : 'Update uspesno prosao!');
      navigate(PATH_DASHBOARD.administrators.list);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFSwitch
                name="IsSuperUser"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Super administrator
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />

              <RHFSwitch
                name="IsHost"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Host
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />

              <RHFTextField name="FirstName" label="Ime" />
              <RHFTextField name="LastName" label="Prezime" />
              <RHFTextField name="FbId" label="Firebase ID" />
              <RHFSelect name="CarSchoolFk" label="Skola" placeholder="Skola">
                {schools.map((option) => (
                  <option key={option.Id} value={option.Id}>
                    {option.Name}
                  </option>
                ))}
              </RHFSelect>
              <RHFSelect name="RoleFk" label="Rola" placeholder="Grad">
                {roles.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFSelect>

              <RHFTextField name="Email" label="Email" />
              <RHFTextField name="PhoneNumber1" label="Broj telefona" />
              <RHFTextField name="PhoneNumber2" label="Broj telefona 2" />

              <RHFSwitch
                name="NotifyDailyReports"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Dnevni izveštaji(email)
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />

              <RHFSwitch
                name="NotifyMontlyReports"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Mesecni izveštaji(email)
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />

              <RHFSwitch
                name="NotifyYearlyReports"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Godisnji izveštaji(email)
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />

              <RHFSwitch
                name="NotifyLicenceHR"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Licence zaposlenih(email i notifikacija)
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />

              <RHFSwitch
                name="NotifyExpiredContractSpace"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Licence i istek ugovora za prostor(email i notifikacija)
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />

              <RHFSwitch
                name="NotifyRoute"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Obaveštenje o usaglašavanju rute(email i notifikacija)
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />

              <RHFSwitch
                name="ExpiredMontlyCheckVehicles"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Istek mesečnog(na 6 meseci) pregleda vozila(email i notifikacija)
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />

              <RHFSwitch
                name="ExpiredRegistration"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Obavestenje o isteku registracije vozila(email)
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />

              <RHFSwitch
                name="NotifyExams"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Obavestenje o ispitima(email)
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />


              <RHFSwitch
                name="IsDeleted"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Inaktivan
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Dodaj novog administratora' : 'Sacuvaj izmene'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
