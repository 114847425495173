import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem, getLinkUtilityClass } from '@mui/material';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { fDate, fDateTime } from 'src/utils/formatTime';
import { getPrice } from 'src/utils/formatNumber';
import { openLink } from 'src/utils/links';

// ----------------------------------------------------------------------

SMSTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function SMSTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const theme = useTheme();

  const { Id, tbl_PRIM_SMSAndEmailStatus: { Status }, SMSType, MessageSid, Receivers, Content, SendAt, Data, CreatedBy, CreatedDate, ScholName } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell align="left">{Id}</TableCell>
      <TableCell align="left">{ScholName}</TableCell>
      <TableCell align="left">{Status}</TableCell>
      <TableCell align="left">{SMSType}</TableCell>
      <TableCell align="left">{MessageSid}</TableCell>
      <TableCell align="left">{Receivers}</TableCell>
      <TableCell align="left">{Content}</TableCell>
      <TableCell align="left">{fDateTime(SendAt)}</TableCell>
      <TableCell align="left">{JSON.stringify(Data)}</TableCell>
      <TableCell align="left">{CreatedBy}</TableCell>
      <TableCell align="left">{fDateTime(CreatedDate)}</TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                disabled
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Izmeni
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
