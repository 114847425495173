import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem, getLinkUtilityClass, Modal, Box, Button } from '@mui/material';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { fDate, fDateTime } from 'src/utils/formatTime';
import { getPrice } from 'src/utils/formatNumber';
import { openLink } from 'src/utils/links';

// ----------------------------------------------------------------------

EmailsTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function EmailsTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const theme = useTheme();

  const { Id, tbl_PRIM_SMSAndEmailStatus: { Status }, Cc, ScholName, Subject, Receivers, SendAt, Body, CreatedBy, CreatedDate } = row;

  const [openMenu, setOpenMenuActions] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell align="left">{Id}</TableCell>
      <TableCell align="left">{ScholName}</TableCell>
      <TableCell align="left">{Status}</TableCell>
      {/* <TableCell align="left">{Cc}</TableCell>
      <TableCell align="left">{Bcc}</TableCell> */}
      <TableCell align="left">{Receivers}</TableCell>
      <TableCell align="left">{Subject}</TableCell>
      <TableCell align="left"><Button onClick={() => { setOpenModal(true) }}>Email</Button> </TableCell>
      <TableCell align="left">{fDateTime(SendAt)}</TableCell>
      <TableCell align="left">{CreatedBy}</TableCell>
      <TableCell align="left">{fDateTime(CreatedDate)}</TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                disabled
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Izmeni
              </MenuItem>
            </>
          }
        />
      </TableCell>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div dangerouslySetInnerHTML={{ __html: Body }} />
        </Box>
      </Modal>
    </TableRow>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
