import { paramCase, capitalCase } from 'change-case';
import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// _mock_
import { _userList } from '../../../_mock';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import SchoolNewEditForm from 'src/sections/@dashboard/schools/SchoolNewEditForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import axiosInstance from 'src/utils/axios';
import AdministratorNewEditForm from 'src/sections/@dashboard/administrators/AdministratorNewEditForm';
// sections

// ----------------------------------------------------------------------

export default function AdministratorCreate() {
  const { themeStretch } = useSettings();

  const { pathname } = useLocation();

  const { id = '' } = useParams();

  const isEdit = pathname.includes('izmeni');

  const [currentEditData, setCurrentEditData] = useState(null);

  const [schools, setSchools] = useState([]);

  useEffect(() => {
    load()
  }, [id, isEdit]);

  async function load() {

    const schools = await axiosInstance.get('/office/schools');
    setSchools(schools.data);

    if (isEdit && id) {
      const result = await axiosInstance.get(`/office/administrators-id?id=${parseInt(id)}`);
      setCurrentEditData(result.data);
    }
  }

  return (
    <Page title="Administrator: Kreiraj novog administratora">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Kreiraj novog administratora' : 'Izmeni administratora'}
          links={[
            { name: 'Pregled', href: PATH_DASHBOARD.root },
            { name: 'Administrator', href: PATH_DASHBOARD.administrators.list },
            { name: !isEdit ? 'Novi Administrator' : capitalCase(`${currentEditData?.FirstName} ${currentEditData?.LastName}` || "") },
          ]}
        />

        <AdministratorNewEditForm isEdit={isEdit} currentEditData={currentEditData} schools={schools} />
      </Container>
    </Page>
  );
}
