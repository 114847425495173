import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem, getLinkUtilityClass } from '@mui/material';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { fDate, fDateTime } from 'src/utils/formatTime';
import { getPrice } from 'src/utils/formatNumber';
import { openLink } from 'src/utils/links';

// ----------------------------------------------------------------------

InvoicesTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function InvoicesTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const theme = useTheme();

  const { FirstName, Amount, PaymentDate, InvoiceNumber, InvoiceDate, Name, CandidateId, InvoiceId, PaymentId } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell align="left">{openLink(`https://online.betasoft.rs/placanja/${PaymentId}/izmeni`, "Placanje")}</TableCell>
      <TableCell align="left">{openLink(`https://online.betasoft.rs/kandidati/${CandidateId}/izmeni`, "Skola")}</TableCell>
      <TableCell align="left">{openLink(`https://online.betasoft.rs/fakture/${InvoiceId}/izmeni`, "Faktura")}</TableCell>

      <TableCell align="left">{Name || FirstName}</TableCell>
      <TableCell>{getPrice(Amount)}</TableCell>
      <TableCell>{fDate(PaymentDate)}</TableCell>
      <TableCell align="left">{InvoiceNumber}</TableCell>
      <TableCell>{fDate(InvoiceDate)}</TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                disabled
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Izmeni
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
