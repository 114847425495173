import axios from 'axios';
import { AUTH } from 'src/contexts/FirebaseContext';
import { getUnixTime } from 'date-fns'

// config
import { HOST_API, HOST_API_KEY } from '../config';

const LAST_TOKEN_EXPIRATION_TIME_MINUT = 60 * 30; // second * minutes

// ----------------------------------------------------------------------

let lastCall = null;

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'x-api-key': HOST_API_KEY
  }
});


axiosInstance.interceptors.request.use(async config => {
  config.headers.common = { "Content-Type": "application/json" };

  const u = AUTH.currentUser;

  if (u) {

    try {

      if (lastCall) {

        const currTime = getUnixTime(new Date());
        const currTimeDiff = currTime - lastCall;

        // console.log("token currTimeDiff", currTimeDiff);

        if (currTimeDiff < LAST_TOKEN_EXPIRATION_TIME_MINUT) {
          //   console.log("token already exists")
          config.headers.Authorization = await u.getIdToken();
        } else {
          //   console.log("token new")
          config.headers.Authorization = await u.getIdToken(true);
          lastCall = currTime;
        }
      } else {
        //  console.log("token new")
        config.headers.Authorization = await u.getIdToken(true);
        lastCall = getUnixTime(new Date());
      }

    } catch (error) {
      // console.log("token new error")
      config.headers.Authorization = await u.getIdToken(true);
      lastCall = getUnixTime(new Date());
    }
  }

  return config;
}, (error) => {
  lastCall = null;
  return Promise.reject(error)
});


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || error)
);

export default axiosInstance;
